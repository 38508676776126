import React,{ useState, useEffect } from 'react';
import Globals from '../contexts/Globals';
import I18nLib from '../lib/I18nLib';
import BlackoutAPI from '../lib/BlackoutAPI';
import { useParams } from 'react-router-dom';
import { logError } from '../lib/Debug';
import moment from 'moment';

import LoadingBlinker from './LoadingBlinker';
import LoaderButton from './LoaderButton';
import Club from './Club';

let countdownTimeout = null;
let secondsBetweenAdViews = 121; // 2 mins + 1 second for safety as suggested by Dean@AppLixer
let actualCurrentStatus = 'loading';

function AdViewer() {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	const { clubId } = useParams();
	let testClub = {id:'testing'};
	
	let [status,setStatus] = useState('loading');
	let [isShowingAd,setIsShowingAd] = useState(false);
	let [club,setClub] = useState(testClub);
	let [checkTimeout,setCheckTimeout] = useState(null);
	let [secondsToWait,setSecondsToWait] = useState(0);
	
	const updateCountdown = (secondsToWait) => {
		setSecondsToWait(secondsToWait);
		if(secondsToWait>0){
			if(countdownTimeout) clearTimeout(countdownTimeout);
			countdownTimeout = setTimeout(()=>{
				countdownTimeout = null;
				updateCountdown(secondsToWait-1);
			},1000);
		}
	};
	
	const isSigningContracts = (newStatus) => (newStatus||actualCurrentStatus) === 'ad-watched' || (newStatus||actualCurrentStatus) === 'fb-watched';
	
	const setStatusWrapper = (newStatus) => {
		//console.log('Request to change',actualCurrentStatus,'to',newStatus);
		
		// Prevent invalid status from "Signing contracts" state
		if(isSigningContracts() && !['ad-timed-out','ad-validated','ad-validation-failed'].includes(newStatus)){
			return;
		}
		
		// Prevent any other change once ad-validated status is reached
		if(actualCurrentStatus === 'ad-validated') return;
		
		//console.log('Setting local status',newStatus);
		actualCurrentStatus = newStatus;
		setStatus(newStatus);
	};
	
	const refreshClub = async (nextStatus=null) => {
		if(clubId){
			//Load club
			let club = await BlackoutAPI.get({
				endpoint: 'clubs',
				id: clubId,
				headers: {
					'Get-Developer-Status':'1',
					'Get-Applixir-Token':'1',
				}
			}).catch(logError);
			
			if(club && club.data){
			
				// Testing
				// club.data.lastApplixirToken = 'abc123';
				// club.data.lastApplixirViewDate = moment().subtract(53,'seconds').toISOString();
				
				let lastViewDate = club.data.lastApplixirViewDate;
				if(lastViewDate && moment().diff(lastViewDate,'seconds')<secondsBetweenAdViews){
					club.secondsToWait = (secondsBetweenAdViews - moment().diff(lastViewDate,'seconds')) || 0;
					updateCountdown(club.secondsToWait);
				}
				
				setClub({...club.data});
			}
			if(nextStatus) setStatusWrapper(nextStatus);
			
			return club;
		} else {
			setClub(testClub);
			if(nextStatus) setStatusWrapper(nextStatus);
			return testClub;
		}
	};
	
	useEffect(()=>{
		refreshClub('default');
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[clubId]);
	
	const showOverlay = () => {
		var els = document.getElementsByTagName( 'html','body' );
		for( let el of els ) el.classList.add('noscroll');
	};
	
	const hideOverlay = () => {
		var els = document.getElementsByTagName( 'html','body' );
		for( let el of els ) el.classList.remove('noscroll');
	};

	const hideAd = (newStatus) => {
		setIsShowingAd(false);
		hideOverlay();
		if(newStatus) setStatusWrapper(newStatus);
	};
	
	const showVideo = () => {
		let token = Math.random().toString(36).substring(7);
		let timeoutId;
		
		const checkForAdSuccess = () => {
			if(!clubId) return;
			
			let attemptsRemaining = 20;
			
			const check = () => {
				let timeout = setTimeout(()=>{
					console.log('Checking club... Looking for token',token);
					refreshClub().then(res=>{
						if(res && res.data){
							let club = res.data;
							console.log('Last token',club.lastApplixirToken,'===',token);
							if(club.lastApplixirToken === token){
								console.log('TOKEN SUCCESS!');
								setStatusWrapper('ad-validated');
							} else if(attemptsRemaining>0){
								// Try again soon
								attemptsRemaining--;
								check();
							} else {
								setStatusWrapper('ad-validation-failed');
							}
						}
					}).catch(logError);
				},500);
				
				setCheckTimeout(timeout);
			};
			
			check();
		};
		
		const adStatusCallback = (newStatus) => {
			// The status parameter will turn the following values:
			// 'ad-blocker' = an ad blocker was detected ✅
			// 'network-error' = connectivity problem ✅
			// 'no-zoneId' = the required zoneId value is missing ✅
			// 'ad-started' = an ad has been loaded and is starting. Do NOT block this. ✅
			// 'fb-started' = a fallback ad has been started. Do NOT block this. ✅
			// 'ad-watched' = an ad was presented and watched successfully (does NOT mean it will be validdated)
			// 'ad-rewarded' = an ad was watched and validated successfully (Newly added, after we implemented our own success detection)
			// 'ad-interrupted' = ad was ended early and was unsuccessful
			// 'ads-unavailable' = no ads were available from the ad server ✅
			// 'fb-watched' or 'fb-rewarded' = fallback mode successfully displayed a fallback ad
			// 'sys-closing' = this is the final message prior to the ad window closing ✅
			if (newStatus){
				if(timeoutId) clearTimeout(timeoutId);
				console.log('Applixir status: ' + newStatus);
				
				let timeCancelStatuses = ['ad-blocker','network-error','cors-error','ad-interrupted','ads-unavailable'];
				if(timeCancelStatuses.includes(newStatus)){
					console.log(`Cancelling ad-view timer`);
					// If ad was skipped, let's cancel server lastViewDate
					// Before we do run the ad, we need to confirm that we're allowed to watch one now
					BlackoutAPI.patch({
						endpoint: 'ad-views',
						id: clubId,
						headers: {
							//Token: globals.userState.token.accessToken, (Auth not required)
							'Content-Type': 'application/vnd.api+json'
						},
						data: {
							id: clubId,
							type: 'ad-views',
							attributes:{
								cancelDesktopAdView: true
							}
						}
					}).catch(logError);
				}
				
				if(newStatus !== 'sys-closing') setStatusWrapper(newStatus);
				
				let closeStatuses = ['ad-blocker','network-error','cors-error','ad-watched','ad-rewarded','fb-watched','fb-rewarded','ad-interrupted','ads-unavailable','sys-closing'];
				
				// Hide loader
				if(closeStatuses.includes(newStatus)){
					hideAd();
				}
				
				// Check for ad success
				if(isSigningContracts(newStatus)){
					checkForAdSuccess();
				}
			}
		};
		
		setIsShowingAd(true);
		showOverlay();
				
		// Cancel previous check polling
		if(checkTimeout){
			clearTimeout(checkTimeout);
			setCheckTimeout(null);
		}
		
		// Before we do run the ad, we need to confirm that we're allowed to watch one now
		BlackoutAPI.patch({
			endpoint: 'ad-views',
			id: clubId,
			headers: {
				//Token: globals.userState.token.accessToken, (Auth not required)
				'Content-Type': 'application/vnd.api+json'
			},
			data: {
				id: clubId,
				type: 'ad-views',
				attributes:{
					requestDesktopAdView: true
				}
			}
		}).then(res=>{
			
			if(res && res.data){
				
				let custom = token; //{token}; Use basic string to be safe, in case applixir changes how they support encoding of JSON strings
				
				let options = {
					zoneId: 2050, // Developer/test
					accountId: 4713,
					gameId: 8262,
					fallback: 1,
					adStatusCb: adStatusCallback,
					//verbosity: 5, // Get full logging
					
					//Callback
					//https://api.blackout.games/v1/ad-views?account_id={ACCOUNT_ID}&game_id={GAME_ID}&userId={CUSTOM1}&custom={CUSTOM2}&timestamp={CALLBACK_TIMESTAMP}&uniquetid={UNIQUETID}&checksum={CHECKSUM}
					userId: club.id,
					custom: typeof custom === 'string' ? custom : encodeURIComponent(JSON.stringify(custom)),
					
				};
				
				if(club && !club.isDeveloper){
					// Use live ads
					options.zoneId = 6089; // New game id, Nov 2023
				}
				
				// Force fallback ad
				//options.zoneId = 9999;
				
				console.log('window.location.hash',window.location.hash);
				if(window.location.hash && window.location.hash.indexOf('verbose')){
					options.verbosity = 5;
				}
				
				//let testStatus = 'ad-watched'; // ad-rewarded
				let testStatus = null;
				
				if(testStatus){
					setTimeout(()=>{
						hideAd(testStatus);
					},1000);
				} else {
					// Call the following function when you want to present a video ad to your user
					window.invokeApplixirVideoUnit(options);
				}
				
				// If nothing happens after 10s there may be some sort of tracking protection or ad blocking that hasn't been detected by applixir
				if(timeoutId) clearTimeout(timeoutId);
				timeoutId = setTimeout(()=>{
					timeoutId = null;
					hideAd();
					setStatusWrapper('ad-timed-out');
				},20000);
				
			} else {
				console.log('UNEXPECTED res',res);
			}
			
		}).catch(error=>{
			if(error.response && error.response.status === 429){
				console.log('error.response',error.response);
				if(error.response.data && error.response.data.adViews && error.response.data.adViews.lastViewDate){
					
					// We need to wait a while
					const lastViewDate = error.response.data.adViews.lastViewDate;
					let secondsToWait = secondsBetweenAdViews - moment().diff(lastViewDate,'seconds');
					setIsShowingAd(false);
					updateCountdown(secondsToWait);
					
				} else {
					logError(new Error(`We got a 429 response, but we couldn't find lastViewDate`));
				}
			} else logError(error);
		});
		
		console.log('Blackout Ad-View Token',token);
		
	};
	
	let content;
	
	let watchAdButton = (
		<div style={{maxWidth:'200px'}} className="center-div">
			{secondsToWait>0?<div className="warning-text small-text gap-after sm">Next ad available in {secondsToWait} seconds</div>:null}
			<LoaderButton
				onClick={()=>showVideo()}
				className="loader-button"
				isLoading={isShowingAd}
				disabled={secondsToWait>0}
			>
				{i18n('ad-viewer.watch-ad','WATCH AD')}
			</LoaderButton>
		</div>
	);
	
	if(status === 'ad-blocker'){
		content = (
			<>
				<div className="error-text">
					{i18n('ad-viewer.ad-blocker','Please disable your ad blocker')}
				</div>
			</>
		);
	} else if(status === 'ads-unavailable'){
		content = (
			<>
				<div className="ad-viewer-message gap-after lg">
					<div className="error-text gap-after sm">{i18n('ad-viewer.ads-unavailable','There are no ads available to watch right now')}</div>
					<h3 className="small-text">{i18n('ad-viewer.try-again-later','Please try again later')}</h3>
				</div>
				{watchAdButton}
			</>
		);
	} else if(status === 'ad-interrupted'){
		content = (
			<>
				<div className="ad-viewer-message gap-after lg">
					<div className="error-text gap-after sm">{i18n('ad-viewer.ad-interrupted','The ad was interrupted before it completed')}</div>
					<h3 className="small-text">{i18n('ad-viewer.start-again','Please start again')}</h3>
				</div>
				{watchAdButton}
			</>
		);
	} else if(isSigningContracts()){
		content = (
			<>
				<div className="ad-viewer-message">
					<h3 className="small-text">{i18n('ad-viewer.updating','Signing contracts')}</h3>
					<LoadingBlinker/>
				</div>
			</>
		);
	} else if(status === 'ad-validated'){
		content = (
			<>
				<div className="ad-viewer-message">
					<div className="ad-success-text success-text gap-after"><span className="icon-ok-circled ad-watched-tick"></span> {i18n('ad-viewer.ad-watched','Success')}</div>
					<h3 className="small-text">{i18n('ad-viewer.return-to-game','Please return to the game')}</h3>
				</div>
			</>
		);
	} else if(status === 'ad-validation-failed'){
		content = (
			<>
				<div className="ad-viewer-message">
					<div className="error-text gap-after sm">{i18n('ad-viewer.ad-validation-failed','Ad validation failed – Please try again')}</div>
					<div className="gap-after">{i18n('ad-viewer.problems-let-us-know','If this problem persists, please let us know at')} <a href="mailto:support@blackout.games">support@blackout.games</a></div>
					{watchAdButton}
				</div>
			</>
		);
	} else if(status === 'ad-timed-out'){
		content = (
			<>
				<div className="ad-viewer-message">
					<div className="error-text gap-after sm">{i18n('ad-viewer.ad-timed-out','Ad failed to load. Please ensure there are no ad-blockers, and that any tracking prevention and privacy settings are not too strict.')}</div>
					<div className="gap-after">{i18n('ad-viewer.problems-let-us-know','If this problem persists, please let us know at')} <a href="mailto:support@blackout.games">support@blackout.games</a></div>
					{watchAdButton}
				</div>
			</>
		);
	} else if(status === 'network-error'){
		content = (
			<>
				<div className="ad-viewer-message">
					<div className="error-text gap-after sm">{i18n('ad-viewer.network-error','There is a problem with internet connectivity')}</div>
					<h3 className="small-text">{i18n('ad-viewer.try-again-later','Please try again later')}</h3>
				</div>
			</>
		);
	} else if(status === 'loading'){
		content = (
			<LoadingBlinker/>
		);
	} else {
		content = watchAdButton;
	}
	
	return (
		<>
			<div className="flex-column-center">
				<div className="flex-fixed center-text">
					<div>
						<img src={`${globals.config.baseImageUrl}/signup-website/broadcast-building.png`} className="broadcast-building" alt={i18n('ad-viewer.broadcast-building','Broadcast Building')} />
					</div>
					<h1 className="header-on-dark ad-viewer-header">{i18n('ad-viewer.broadcast-building','Broadcast Building')}</h1>
					<div className="flex-row gap-after lg">
						<div className="flex-cell flex-column-center" style={{minHeight:'80px'}}>
							<h2 className="no-gap-after">{i18n('ad-viewer.title','Ad Viewer')}</h2>
							{status!=='loading'&&club.id!==testClub.id?(
								<h2 className="no-gap-after">{i18n('ad-viewer.for-club','For Club')}</h2>
							):null}
						</div>
						{status!=='loading'?(
							<div className="flex-cell flex-column-center">
								{club.id===testClub.id?(
									<span className="warning-text vertical-middle">
										TEST MODE<br/>
										<span className="small-text">(NO CLUB PROVIDED)</span>
									</span>
								):(
									<>
										<div className="flex-row">
											<Club club={club} center="center" />
										</div>
										<div>
											{club.isDeveloper?(
												<span className="warning-text vertical-middle small-text">
													TEST MODE (DEV CLUB)
												</span>
											):null}
										</div>
									</>
								)}
							</div>
						):null}
					</div>
					{content}
					<div id="overlay" style={isShowingAd?{}:{display:'none'}}>
						<div id="applixir_vanishing_div" hidden>
							<iframe id="applixir_parent" allow="autoplay" title="Applixir Ad Viewer"></iframe>
						</div>
					</div>
				</div>
			</div>
		</>
	);
	
}

export default AdViewer;