import { createContainer } from 'unstated-next';
import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import config from '../config.json';
import UsersLib from '../lib/UsersLib';

/**
 * Use this to store any items with merge support
 * This allows us to always track the latest version
 * of an item. e.g. if two separate components merge
 * an item, without this, the second one would be using
 * a stale version of the data from useState.
 */
let watcher = {};

const appPaths = [
	'login',
	'signup',
	'profile',
	'settings',
	'subscriptions',
	'feedback',
	
	/**
	 * If these are contained in this list, it prevents the 'Account'
	 * link in the main menu from returning the user to login
	 */
	//'/pagenotfound'
	//'/unsubscribe',
	//'/forgotpassword',
	
];

const sportConfigs = {
	rugby: {
		discordUrl: 'https://discord.gg/vayT2pV',
		favicon: `https://dr8u6iu2x4v0s.cloudfront.net/website/blackout.rugby/2024/favicon_rugby.ico`,
		htmlTitle: 'Blackout Rugby - Best Online Rugby Manager Game',
		baseUrl: 'https://blackout.rugby',
	},
	football: {
		discordUrl: 'https://discord.gg/MbW3Du3sPX',
		favicon: `https://dr8u6iu2x4v0s.cloudfront.net/website/blackout.rugby/2024/favicon_football.ico`,
		htmlTitle: 'Blackout Football - Best Online Football Manager Game',
		baseUrl: 'https://blackout.football',
	},
};

// State management using react hooks and unstated-next lib
function useGlobalState(){
	
	let defaultUserState = {
		loggedIn: false,
		token: null,
		manager: null,
		clubs: null,
		social: null,
	};
	
	// Load inviter from localStorage
	let storedInviter = localStorage.getItem('inviter');
	storedInviter = storedInviter ? JSON.parse(storedInviter) : null;
	
	let location = useLocation();
	let history = useHistory();
	
	let isAppPath = appPaths.includes(location.pathname.split('/')[1]);
	const defaultSport = window.location.hostname.includes('football') ? 'football' : 'rugby';
	const isDevMode = window.location.hostname.includes('local')||window.location.hostname.includes('127.0.0.1');
	
	//------------------ Defaults
	
	let [userState, setUserState] = useState({ ...defaultUserState });
	let [userSubs, setUserSubs] = useState(null);
	let [inviter, setInviter] = useState(storedInviter);
	let [i18n, setI18n] = useState({});
	let [isHome, setIsHome] = useState(true);
	let [appHeight, setAppHeight] = useState(null);
	let [scrollY, setScrollY] = useState(0);
	let [appPath,setAppPath] = useState(isAppPath ? location.pathname : '/profile');
	
	let [isCheckingFacebook,setIsCheckingFacebook] = useState(false);
	let [fbEnabled,setFbEnabled] = useState(false);
	let [appleEnabled] = useState(true);
	let [googleEnabled] = useState(true);
	let [sport,setSport] = useState(defaultSport);
	let [transition, setTransition] = useState(sport!=='rugby'); // ⚠️ both transition and isFootball must be set to the same condition or it doesn't display correctly
	
	const sportConfig = sportConfigs[sport];
	
	useEffect(()=>{
	
		const changeFavicon = (faviconURL) => {
			const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
			link.type = 'image/x-icon';
			link.rel = 'shortcut icon';
			link.href = faviconURL;
			document.getElementsByTagName('head')[0].appendChild(link);
		};
		
		document.title = sportConfig.htmlTitle;
		changeFavicon(sportConfig.favicon);
		
	},[sportConfig]);
	
	//------------------ App Routing (Account section)
	
	let pathIsLoginOrSignup = ['/login','/signup'].includes(appPath);
	let appPathChanged = isAppPath && location.pathname !== appPath;
	
	// Detect when page changes (to non-home page)
	if(appPathChanged){
		if(userState.loggedIn && pathIsLoginOrSignup){
			// Divert to profile
			setAppPath('/profile');
		} else {
			document.getElementById('viewport').scrollIntoView();
			setAppPath(location.pathname);
		}
		return;
	}
	
	return GlobalState.current = {
		
		//------------------ Constant globals
		
		isDevMode,
		appFooterHeight: 400,
		appPaths,
		phoneMediaQuery: 'only screen and (max-width : 480px)',
		tabletMediaQuery: 'only screen and (max-width : 768px)',
		laptopMediaQuery: 'only screen and (max-width : 1024px)',
		wideMediaQuery: 'only screen and (min-width: 1921px)',
		
		// Add config details
		config,
		sportConfig,
		
		//------------------ Actual state values
		
		userState, setUserState,
		userSubs, setUserSubs,
		inviter, setInviter,
		i18n, setI18n,
		isHome, setIsHome,
		appHeight, setAppHeight,
		scrollY, setScrollY,
		isCheckingFacebook,setIsCheckingFacebook,
		fbEnabled,setFbEnabled,
		appPath,setAppPath,
		appleEnabled,
		googleEnabled,
		sport,setSport,
		transition, setTransition,
		
		//------------------ Helpers
		
		mergeUserState(partialUserState){
			if(partialUserState.clubs){
				partialUserState.clubs.sort(( a, b )=>{
					if ( a.creationDate < b.creationDate ) return -1;
					if ( a.creationDate > b.creationDate ) return 1;
					return 0;
				});
			}
			watcher.userState = { ...(watcher.userState || userState), ...partialUserState };
			setUserState(watcher.userState);
			UsersLib.updateUser(watcher.userState);
		},
		
		resetUserState(){
			setUserState({ ...defaultUserState });
			
			// If not on the home page, send user to /login
			if(isAppPath) history.push('/login');
		},
		
		isAccountPath(path){
			return appPaths.includes(path.split('/')[1]);
		},
		
	};
	
}

const GlobalState = createContainer(useGlobalState);
export default GlobalState;