import React, { useState } from 'react';
import BlackoutAPI from '../../lib/BlackoutAPI';
import LoaderButton from '../LoaderButton';
import Globals from '../../contexts/Globals';
import I18nLib from '../../lib/I18nLib';
import { log, logRender, logError } from '../../lib/Debug';

function EmailPreferences() {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	
	const [optedOut,setOptedOut] = useState(globals.userState.manager.emailPreferences.optedOut);
	const [isSaving,setIsSaving] = useState(false);

	// updates input states
	function handleChange(e){
		log(`------- handle change`,e);
		setOptedOut(!optedOut);
	}

	function handleSubmit(e){
		log(`------- handle submit`,e);
		e.preventDefault();
		if(!isSaving){
			setIsSaving(true);

			BlackoutAPI.post({
				endpoint: 'email-preferences',
				headers: {
					'Content-Type': 'application/vnd.api+json'
				},
				data: {
					type: 'email-preferences',
					attributes:{
						Email: globals.userState.manager.email,
						OptOut: optedOut
					}
				}
			}).then(res=>{
				log(` **** EmailPreferences save success ***** `,res);
				setIsSaving(false);
				globals.userState.manager.emailPreferences.optedOut = optedOut;
				globals.mergeUserState({emailPreferences:globals.userState.manager.emailPreferences});
			}).catch(err=>{
				setIsSaving(false);
				logError(` **** EmailPreferences save error ***** `, err.message);
			});
			
		}
	}

	
	logRender(`Email Preferences -- render`);
		
	return (
		<div className="sub-panel center-text">
			<h3 className="center-text">{i18n('account.email-preferences','Email Preferences')}</h3>
			<div className="data-container account">
				<form onSubmit={handleSubmit} className="gap-after">
					<label>
						{i18n('account.can-send-email','You can send me emails')}:
						<input type="checkbox" id="events" checked={!optedOut} onChange={handleChange} />
					</label><br />
				</form>
				<LoaderButton
					onClick={handleSubmit}
					className="sm gap-after"
					isLoading={isSaving}
				>{i18n('buttons.update','Update')}</LoaderButton>
			</div>
		</div>
	);
	
}

export default EmailPreferences;