import debugCreator from 'debug';
window.debugName = 'rugbyweb';

//-------------- Log streams

const log = debugCreator(`${window.debugName}:log`);
const logError = debugCreator(`${window.debugName}:error`);
const logAPI = debugCreator(`${window.debugName}:api`);
const logRender = debugCreator(`${window.debugName}:renders`);

//-------------- Tell browser which streams to log

const isDevMode = window.location.hostname.includes('local')||window.location.hostname.includes('127.0.0.1');
const debugValue = `${window.debugName}:log,${window.debugName}:api,${window.debugName}:error`;

if(isDevMode){
	console.log('isDevMode',isDevMode);
	localStorage.debug = debugValue;
	window.setInterval(()=>{
		if(localStorage.debug !== debugValue)
			localStorage.debug = debugValue;
	},500);
}

export {
	log,
	logError,
	logAPI,
	logRender,
};