import React from "react";
import Globals from "../contexts/Globals";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function SwitchSport(){

	let globals = Globals.useContainer();
	const otherSport = globals.sport === 'rugby' ? 'football' : 'rugby';

	const switchToFootballHandler = () => {
		if (globals.sport === 'football') {
			globals.setSport('rugby');
			globals.setTransition(false);
		}
		else {
			globals.setSport('football');
			globals.setTransition(true);
		}

	};

	return (
		<>
		<Link className="menu-link" onClick={switchToFootballHandler}> Switch to {otherSport}</Link> 
		</>
	);

}
export default SwitchSport;