import React,{ useState,useEffect } from 'react';
import {shuffle} from '../lib/util';
import Globals from '../contexts/Globals';
import I18nLib from '../lib/I18nLib';

const testimonies = shuffle([
	{
		author: 'Alexander',
		source: 'Steam Reviewer',
		sourceI18n: 'home.steam-reviewer',
		text: 'the type of game I have been waiting for, for a long time',
		textI18n: 'home.testimony-(Alexander)',
	},
	{
		author: 'BIF Infiniti',
		source: 'Steam Reviewer',
		sourceI18n: 'home.steam-reviewer',
		text: 'nothing else like it',
		textI18n: 'home.testimony-(BIF Infiniti)',
	},
	{
		author: 'choob',
		source: 'Steam Reviewer',
		sourceI18n: 'home.steam-reviewer',
		text: 'if you like sports management, you\'ll love this!',
		textI18n: 'home.testimony-(choob)',
	},
	{
		author: 'n1KEYT',
		source: 'Steam Reviewer',
		sourceI18n: 'home.steam-reviewer',
		text: 'super smooth and clean',
		textI18n: 'home.testimony-(n1KEYT)',
	},
	{
		author: 'Falcon',
		source: 'Steam Reviewer',
		sourceI18n: 'home.steam-reviewer',
		text: 'excellently thought out and implemented',
		textI18n: 'home.testimony-(Falcon)',
	},
]);

function Testimonies() {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	let [cursor,setCursor] = useState(0);
	let [isTransitioning,setIsTransitioning] = useState(false);
	let testimony = {...testimonies[cursor]};
	
	if(globals.sport === 'football'){
		testimony.text += ' (placeholder from rugby)';
		delete testimony.textI18n;
	}
	
	useEffect(()=>{
		let timeout = setTimeout(()=>{
			timeout = null;
			setIsTransitioning(true);
			let el = document.getElementById('testimony');
			el.ontransitionend = e => {
				if(e.target !== el) return;
				let newCursor = ++cursor;
				if(newCursor===testimonies.length){
					newCursor = 0;
				}
				setCursor(newCursor);
				setIsTransitioning(false);
				el.ontransitionend = null;
			};
		},7000);
		
		return ()=>{
			if(timeout) clearTimeout(timeout);
		};
	},[cursor]);
	
	let className = [];
	if(isTransitioning) className.push('fadeout');
	
	return (
		<div className="center-text flex-column-center" style={{ height: '100%' }}>
			<div id="testimony" className={className.join(' ')}>
				<div id="testimony-text">“{i18n(testimony.textI18n,testimony.text)}”</div>
				<div id="testimony-author">{testimony.author} ({i18n(testimony.sourceI18n,testimony.source)})</div>
			</div>
		</div>
	);
	
}

export default Testimonies;