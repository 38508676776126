import React, { useState, useEffect } from 'react';
import Globals from '../../contexts/Globals';
import I18nLib from '../../lib/I18nLib';
import { log, logRender, logError } from '../../lib/Debug';

import BlackoutAPI from '../../lib/BlackoutAPI';
import util from '../../lib/util';
import LoaderButton from '../LoaderButton';
import LoadingBlinker from '../LoadingBlinker';

function InviteInfo() {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	
	let [friend,setFriend] = useState('');
	let [isSettingReferrer,setIsSettingReferrer] = useState(false);
	let [validationMessage,setValidationMessage] = useState(null);

	let headers = {
		Token: globals.userState.token.accessToken,
		'Content-Type': 'application/vnd.api+json',
	};

	useEffect(()=>{
		if(!globals.userState.social){
			BlackoutAPI.get({
				endpoint: 'social',
				id: globals.userState.manager.socialId,
				headers,
				include: 'Friends',
			}).then(res=>{
				globals.mergeUserState({ social: res.data });
			}).catch(logError);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[globals.userState.social]);

	// updates input states
	const handleChange = (e) => {
		setFriend(e.target.value);
	};

	const setInvitedBy = () => {

		let invitedBy = globals.userState?.social?.referrals?.[globals.sport]?.referredByUsername ?? false;
		let invitedByForm = (
			<div className="data-row">
				<div className="key-col">{i18n('account.invited-by','Invited By')}</div>
				<div className="val-col">{invitedBy || i18n('account.nobody','Nobody')}</div>
			</div>
		);
		
		let addInvitedByForm;
		if(!invitedBy){
			addInvitedByForm = (
				<div>
					<form onSubmit={submitInvitedBy}>
						<h3 className="center-text">{i18n('account.add-inviter','Add My Inviter')}</h3>
						
						<div className="input-container">
							<input 
									id='invitedBy'
									type="text" 
									name="referralCode"
									placeholder={i18n('account.their-invite-code',`Other user's invite code`)}
									onFocus={handleFocus}
									onChange={handleChange}
								/>
							{
								validationMessage
								? <div className="validation-text"><span className="icon-attention" />{validationMessage}</div>
								: <div className="form-minor-message"><span className="icon-info-circled" />{i18n('account.cannot-change','Cannot be changed once set')}</div>
							}
						</div>
						
						<LoaderButton
							isSubmitButton={true}
							className="sm"
							isLoading={isSettingReferrer}
						>{i18n('buttons.submit','Submit')}</LoaderButton>
					</form>
					
				</div>
			);
		}

		return {
			invitedByForm,
			addInvitedByForm
		};
	};

	const submitInvitedBy = (e) => {
		e.preventDefault();

		// need to validate the input .....
		let isValid = true;
		if((friend && (friend.length < 5 || friend.length > 24)) || !util.regexCheck(friend)){
			isValid = false;

			setValidationMessage(`${i18n('account.invite-code-length','Must contain 5-24 characters')} (a-z,A-Z,0-9)`);
		} else if (validationMessage){
			setValidationMessage(null);
		}
		log(`isValid`,isValid);
		if(isValid){
			
			setIsSettingReferrer(true);
			
			// Send blackout api post request ......
			BlackoutAPI.post({
				endpoint: 'social',
				include: 'manager',
				headers,
				data: {
					type: 'social',
					attributes:{
						Action: 'referredBy',
						Friend: friend
					}
				}
			}).then(res=>{
				
				log(` **** addReferred success ***** `);
				
				log(`inviteInfo`,res);
				globals.mergeUserState({
					manager: res.included[0],
					social: res.data,
				});
				
			}).catch(err=>{
				if(err.response){
					err = err.response;
					logError(`inviteInfo`,err);
					if(err.data.errors.message.includes('Invalid referral code')){
						setValidationMessage(i18n('account.invalid-invide-code','Invalid invite code'));
					} else if(err.data.errors.message.includes('referredBy on own user')){
						setValidationMessage(i18n('account.cannot-invite-self','Cannot invite yourself'));
					} else if(err.data.errors.message.includes('Cannot refer the user who referred you')){
						setValidationMessage(i18n('account.cannot-invite-inviter','Cannot invite the user who invited you'));
					} else {
						logError('Unrecognised invite error from server');
						setValidationMessage(i18n('account.error-see-console','Error. See console for details.'));
					}
				} else {
					logError(` **** addReferred Error ***** `);
					logError(`err`,err);
					setValidationMessage(i18n('account.error-see-console','Error. See console for details.'));
				}
			}).finally(()=>{
				setIsSettingReferrer(false);
			});
		}
	};
	
	const handleFocus = (/*e*/) => {
		setValidationMessage(null);
	};

	logRender(`InviteInfo -- render`);

	let inviteInfo;
	if(!globals.userState.social){
		return ( 
			<LoadingBlinker text={i18n('account.loading-invite-stats','Loading invite stats')} />
		);
	} else {
		let referralCount = globals.userState.social?.[globals.sport]?.referrals ?? 0;
		let referralCode = globals.userState.social?.id ?? '';
		let {invitedByForm, addInvitedByForm} = setInvitedBy();
		inviteInfo = (
			
				<div>
					<h3 className="center-text">Invites</h3>
					
					<div className="center-text gap-after">
						<div className="data-container invites">
							<div className="data-row">
								<div className="key-col">{i18n('account.invited-friends','Invited Friends')}</div>
								<div className="val-col">{referralCount}</div>
							</div>
							<div className="data-row">
								<div className="key-col">{i18n('account.my-invite-code','My Invite Code')}</div>
								<div className="val-col">{referralCode}</div>
							</div>
							{invitedByForm}
						</div>
					</div>
					{addInvitedByForm}
				</div>
		);
		
		return (
			<div className="sub-panel">
				{inviteInfo}
			</div>
		);

	}
}

export default InviteInfo;